

import Vue from "vue";
type ResolveFunction = (value: boolean) => void;
type RejectFunction = (value: Error) => void;

export interface ConfirmationDialogOptions {
	color?: string;
	width?: number;
	zIndex?: number;
	agree?: {
		text?: string;
		color?: string;
		icon?: string;
	};
	cancel?: {
		text?: string;
		color?: string;
		icon?: string;
	};
}

export default Vue.extend({
	name: "ConfirmationDialog",
	data: () => ({
		dialog: false as boolean,
		resolve: null as ResolveFunction|null,
		reject: null as RejectFunction|null,
		message: "" as string,
		title: "" as string,
		options: {
			color: "primary",
			width: 290,
			zIndex: 200,
			agree: {
				text: "Yes",
				color: "success",
				icon: "",
			},
			cancel: {
				text: "No",
				color: "error",
				icon: "",
			}
		} as ConfirmationDialogOptions,
	}),
	methods: {
		open (title: string, message: string, options: ConfirmationDialogOptions) {
			this.dialog = true;
			this.title = title;
			this.message = message;
			this.options = Object.assign(this.options, options);
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		clearPromise () {
			this.resolve = null;
			this.reject = null;
		},
		agree () {
			if (this.resolve) {
				this.resolve(true);
			}
			this.dialog = false;
			this.clearPromise();
		},
		cancel () {
			if (this.resolve) {
				this.resolve(false);
			}
			this.dialog = false;
			this.clearPromise();
		},
	},
});
