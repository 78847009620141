
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import ConfirmationDialog, { ConfirmationDialogOptions } from "@/components/common/ConfirmationDialog.vue";
import {
	IIngredient,
	IIngredientCategory,
	IIngredientDataReq,
	IIngredientDeleteRES
} from "@common/ingredient";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "Ingredients",
	components: {
		"confirmation-dialog": ConfirmationDialog
	},
	data: () => {
		return {
			search: "" as string,
			tableLoading: false as boolean,
			headers: [
				new TableHeader("Ingredient ID", "start", true, "id"),
				new TableHeader("Name", "start", true, "name"),
				new TableHeader("Category", "start", true, "category"),
				new TableHeader("Unit", "start", true, "unit"),
				new TableHeader("Actions", "start", false, "actions")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			}
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Ingredients");
		if (this.store.getters.ingredients.length === 0) {
			this.tableLoading = true;
			await this.store.dispatch.fetchIngredients();
			if (this.store.getters.ingredientCategories.length === 0) {
				await this.store.dispatch.fetchIngredientCategories();
			}
			this.tableLoading = false;
		}
	},
	computed: {
		categoryName: () => {
			return (categoryId: string, ingredientCategories: IIngredientCategory[]) => {
				for (const category of ingredientCategories) {
					if (category.id === categoryId) {
						return category.name;
					}
				}
			};
		}
	},
	methods: {
		async deleteItem (item: IIngredient) {
			try {
				const dialogTitle = "Product delete";
				const dialogMessage = `Are you sure you want to delete "${item.name}" ingredient?`;
				const dialogOptions: ConfirmationDialogOptions = {
					color: "error",
					width: 400,
					agree: {
						text: "Delete",
						color: "error",
						icon: "delete_forever",
					},
					cancel: {
						text: "Cancel",
						color: "secondary",
						icon: "close",
					}
				};
				const deleteConfirmation = await (this.$refs.deleteIngredient as InstanceType<typeof ConfirmationDialog>).open(
					dialogTitle,
					dialogMessage,
					dialogOptions
				);
				if (deleteConfirmation) {
					const data: IIngredientDataReq = {
						ingredient: item
					};
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/ingredient/delete`,
					};
					const res: AxiosResponse<IServerRES<IIngredientDeleteRES>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						if (res.data.payload.succeeded) {
							this.store.dispatch.deleteIngredient(item);
						}

						this.snackbar = {
							show: true,
							color: "success",
							text: "Ingredient was succesfully deleted.",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		}
	}
});
