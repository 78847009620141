import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":_vm.options.color,"dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.title))])],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"pa-4 text-subtitle-2"},[_vm._v(_vm._s(_vm.message))]),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":_vm.options.agree.color,"small":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.options.agree.icon))]),_vm._v(_vm._s(_vm.options.agree.text)+" ")],1),_c(VBtn,{attrs:{"depressed":"","color":_vm.options.cancel.color,"small":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.options.cancel.icon))]),_vm._v(_vm._s(_vm.options.cancel.text)+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }